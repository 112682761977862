<template>

    <div class="back-to-top" @click="topFunction">
        <span class="material-symbols-outlined">north</span>
    </div>

</template>

<script>

export default {
    name: "BackToTop",
    methods: {
        /**
         * check scroll position
         */
        scrollFunction() {
            let btn = document.querySelector(".back-to-top")

            if (btn) {
                if (document.body.scrollTop > 250 || document.documentElement.scrollTop > 250) {
                    // show btn
                    btn.classList.add("back-to-top--show")
                } else {
                    // hide btn
                    btn.classList.remove("back-to-top--show")
                }
            }
        },

        /**
         * go to top function
         */
        topFunction() {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            })
        }
    }
}
</script>

<style scoped lang="scss">

.back-to-top {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: green;
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 1;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    opacity: 0;
    visibility: hidden;

    &--show {

        opacity: 1;
        visibility: visible;
    }

    &:hover {

        & > .material-symbols-outlined {

        }
    }

    & .material-symbols-outlined {
        font-size: 1.25rem;
        letter-spacing: normal;
        color: #fff;
    }
}

html.contrast {

}

</style>