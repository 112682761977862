<template>

  <form class="form" @submit.prevent="$emit('recoverSubmit')">

    <div class="form__rows">
      <Row type="text" name="mail" :value="recover_data.mail" label="E-mail" @updateValue="updateValue"/>
      <p @click="$emit('emitLogin')">Powrót do logowania</p>
    </div>

    <div class="form__buttons">
      <input type="submit" class="button" value="Przypomnij hasło"/>
    </div>

  </form>

</template>

<script>
import Row from "@/Library/Forms/Row.vue";
import View from "@/views/View.vue"

export default {
  name: "AuthRecoverForm",
  components: {
    Row
  },
  emits: ["emitLogin", "recoverSubmit"],
  extends: View,
  props: {
    recover_data: {
      default() {
        return null
      }
    }
  },
  methods: {
    /**
     * Aktualizowanie wartości przekazywanych przez propsy
     * @param name nazwa
     * @param value wartość
     */
    updateValue(name, value) {
      this.$props["recover_data"][name] = value
    }
  }
}
</script>

<style scoped lang="scss">

.form {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  min-height: 100%;

  &__rows {

    & > div {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    & > p:last-child {
      font-size: 1rem;
      margin-top: 20px;
      text-align: end;
      cursor: pointer;
      color: #007534;
      text-transform: uppercase;

      @media screen and (max-width: 1200px) {
        font-size: calc(0.7rem + (1 * 15 - 0.7 * 15) * ((100vw - 320px) / (1200 - 320)));
      }
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    margin-top: auto;
  }
}

.button {
  font-size: 1rem;
  line-height: 16px;
  font-weight: 400;
  padding: 20px 30px;
  border-radius: 0;
  color: #fff;
  background-image: -webkit-linear-gradient( 0deg, #007534 0%, #007534 100%);
  box-shadow: 2.5px 4.33px 15px 0 rgba(0,0,0,.15);
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;

  @media screen and (max-width: 1200px) {
    font-size: calc(0.7rem + (1.07 * 15 - 0.7 * 15) * ((100vw - 320px) / (1200 - 320)));
    padding: calc(15px + (20 - 15) * ((100vw - 320px) / (1200 - 320))) calc(20px + (30 - 20) * ((100vw - 320px) / (1200 - 320)));
  }

  &:hover {
    background-image: -webkit-linear-gradient( 0deg, #007534 0%, #007534 100%);
  }
}

html.contrast {

  & .form {

    &__rows {

      & > p:last-child {
        color: gold;
      }
    }
  }

  & .button {
    background-image: -webkit-linear-gradient( 0deg, gold 0%, gold 100%);
    color: #000;

    &:hover {
      background-image: -webkit-linear-gradient( 0deg, gold 0%, gold 100%);
    }
  }
}

</style>