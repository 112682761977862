<template>
  <div ref="group" class="row-many-group">
    <label class="row-nice__many many" @change="updateValue" v-for="option in options">
      <input class="many__input"
             type="checkbox"
             v-model="value_arr"
             :value="option.value"
             :checked="values.indexOf(option.value)!==-1"/>
      <span class="many__text">{{ option.name }}</span>
    </label>
  </div>


</template>

<script>
import InputBase from "./InputBase";

export default {
  name: "InputMany",
  extends: InputBase,
  emits: ["updateValue"],
  props: {
    name: {},
    value: {
      default() {
        return [];
      }
    },
    options: {
      default() {
        return [];
      }
    }
  },

  data() {
    return {
      value_arr: [],
      response_type: 'array'
    }
  },
  methods: {
    val() {
      return this.value;
    },
    focus() {
      let input = document.querySelectorAll('.row-many-group input')[0];

      input.focus();
    },
    updateValue(value) {

      let ret = this.value_arr;

      let name = this.name;
      let options = this.options;
      let response_type = this.response_type;

      let ret_show = [];
      this.value_arr.forEach(value => {
        let option = options.find(option => option.value === value);
        if (option)
          ret_show.push({
            name: option.name,
            value: value ?? option.name
          })
      });

      if (response_type === 'string') {
        ret = ret.join(',');
        ret_show = ret_show.join(', ');
      }

      this.$emit('updateValue', name, ret, ret_show);

    },
    updateValueArr() {

      this.response_type = 'array';

      let ret = this.value;
      if (typeof ret === 'string' || ret instanceof String) {

        ret = ret.split(",");
        this.response_type = 'string';
      }

      this.value_arr = ret;
    }
  },
  computed: {

    values() {
      let ret = this.value;
      if (typeof ret === 'string' || ret instanceof String)
        ret = ret.split(",");
      return Object.values(ret);

    },

  },
  mounted() {
    this.updateValueArr();
  },
  watch: {
    value(new_value) {

      this.updateValueArr();

    }

  }
}
</script>

<style scoped lang="scss">
.many {
  padding-right: 12px;

  & > * {
    display: inline-block;
    float: left;
  }

  &__input {
    margin-right: 3px;
    margin-top: 2px;

  }

  &__text {
    margin-top: -2px;
  }

  &:first-child &__input {

    margin-left: 0 !important;
  }
}
</style>