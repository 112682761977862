<template>

  <div class="modal" :class="{'modal--show': show}" @click="close">
    <div class="modal__container" @click.stop>
      <div class="modal__head">
        <p class="modal__head-title">{{ title }}</p>
        <div class="modal__head-close">
          <span class="material-symbols-outlined" @click="close">close</span>
        </div>
      </div>
      <div class="modal__content">
        <slot name="default"></slot>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "TemplateModal",
  props: {
    title: {
      default() {
        return null
      }
    }
  },
  data: () => ({
    show: false,
  }),
  methods: {
    open() {
      let scrollbarWidth = window.innerWidth - document.querySelector("body").offsetWidth + "px"

      document.querySelector(".header").style.transition = "none"
      document.querySelector(".header").style.paddingRight = scrollbarWidth
      document.querySelector("body").style.paddingRight = scrollbarWidth
      document.body.style.overflow = "hidden"
      this.show = true
    },

    close() {
      document.querySelector(".header").style.paddingRight = "0px"
      document.querySelector("body").style.paddingRight = "0px"
      setTimeout(() => {
        document.querySelector(".header").style.transition = "all 0.25s ease 0s"
      }, 250)
      document.body.style.overflow = "auto"
      this.show = false
    }
  }
}
</script>

<style scoped lang="scss">

.modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.2);
  transition: all 0.25s ease 0s;
  z-index: 10;

  display: flex;
  justify-content: end;

  &--show {
    opacity: 1;
    visibility: visible;

    & > .modal__container {
      transform: translateX(0);
    }
  }

  &__container {
    box-shadow: 0 5px 20px 0 rgb(0 0 0 / 10%);

    width: 400px;
    height: 100%;
    background-color: #fff;
    transition: all 0.25s ease 0s;
    transform: translateX(100%);
  }

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
    padding: 30px;

    &-title {
      color: rgb(100, 100, 100);
      text-transform: uppercase;
    }

    &-close {
      display: flex;
      cursor: pointer;

      & .material-symbols-outlined {
        color: rgb(100, 100, 100);
      }

      &:hover {

        & .material-symbols-outlined {
          opacity: 0.5;
        }
      }
    }
  }

  &__content {
    padding: 30px;
    height: calc(100% - 86px);
    overflow: auto;
  }
}

html.contrast {

  & .modal {

    &__container {
      background-color: #000;
      border-left: 1px solid #fff;

      @media screen and (max-width: 400px) {
        border: none;
      }
    }

    &__head {

      &-title {
        color: #fff;
      }

      &-close {

        & .material-symbols-outlined {
          color: #fff;
        }

      }
    }
  }
}

</style>