<template>
  <label class="input-toggle" :class="[this.class]">
    <input :id="id" class="input-toggle__input push-btn" type="checkbox" :name="name" :value="value" v-model="isChecked"
           @change="change"/>
    <label class="input-toggle__item" :for="id"></label>
<!--    <span class="input-toggle__name">{{ label }}</span>-->
  </label>
</template>

<script>
import InputBase from "./InputBase";

export default {
  name: "InputToggle",
  extends: InputBase,
  emits: ['update', 'updateValue'],
  props: {
    'id': {
      default() {
        return 'input-'+parseInt(Math.random()*10000000000);
      }
    },
    'name': {},
    'class': {},
    'value': {},
    'value_input': {
      default() {
        return true;
      }
    },
    'value_input_false': {
      default() {
        return false;
      }
    },
    'label': {},
    'checked': {}
  },
  data() {
    return {
      isChecked: false
    }
  },
  methods: {
    change() {

      let show = this.checked ? this.label : "";

      let ret = this.isChecked ? this.value_input : this.value_input_false;

      this.$emit('update', this.checked)
      this.$emit('updateValue', this.name, ret, show)
    },
    isCheckedUpdate() {
      this.isChecked = false;
      if (this.value_local === this.value_input)
        this.isChecked = true;
      if (this.checked)
        this.isChecked = true;


    },
  },
  computed: {

  },
  mounted() {
    this.isCheckedUpdate();

  },

  watch: {
    value(newValue, oldValue) {

      this.isCheckedUpdate();

    },
    value_input(newValue, oldValue) {

      this.isCheckedUpdate();

    },
    checked(newValue, oldValue) {

      this.isCheckedUpdate();

    },
  }
}
</script>

<style scoped lang="scss">

.input-toggle {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.input-toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

.input-toggle__item {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.input-toggle__item:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .input-toggle__item {
  background-color: #007534;
}

input:focus + .input-toggle__item {
  box-shadow: 0 0 1px #007534;
}

input:checked + .input-toggle__item:before {
  transform: translateX(20px);
}

/* Rounded sliders */
.input-toggle__item {
  border-radius: 34px;
}

.input-toggle__item:before {
  border-radius: 50%;
}

html.contrast {

  & input:checked + .input-toggle__item {
    background-color: gold;
  }

  & input:focus + .input-toggle__item {
    box-shadow: 0 0 1px gold;
  }
}

</style>