<template>
  <div class="app-wrapper">
    <Header/>
    <div class="app-content">
      <router-view/>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Header from "@/views/Components/Header.vue";
import Footer from "@/views/Components/Footer.vue";
import ControllApp from "@/Library/Controll/App"

export default {
  name: "App",
  components: {
    Header,
    Footer
  },
  mixins: [ControllApp]
}

</script>

<style lang="scss">
:root {
  --main_color: #007534
}

*::before,
*::after,
* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}

.texts__main * {
  margin: revert !important;
  padding: revert !important;
}

html, body {
  height: 100%;
  color: #333;
  font-family: Lato, sans-serif;
  font-size: 15px;
  font-style: normal;
  line-height: 26px;
  word-wrap: break-word;
}

html.transition-off *, html.transition-off *::before, html.transition-off *::after {
  transition: none !important;
}

.app-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.app-content {

}

.sticky {
  position: sticky;
  transition: all 0.25s ease 0s;
}

.header.header--first-scroll + .menu-mobile + .app-content .sticky {
  top: calc(86px + 30px);
}

.header.header--hide + .menu-mobile + .app-content .sticky {
  top: 30px;
}

.header.header--show + .menu-mobile + .app-content .sticky {
  top: calc(86px + 30px);
}

html.contrast {
  color-scheme: dark;

  & .vc-container {
    background-color: #000;
  }

  & .vc-title {
    color: #d2d2d2;
    background-color: #000;
  }

  & .vc-arrow {
    background-color: #000;
  }

  & .vc-day-content {
    color: #fff;
  }

  & .vc-highlight-content-light {
    color: #000;
  }
}

/**
 * file style.scss
 */

h1, h2, h3, h4, h5, h6 {
  color: #010f2e;
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 700;
  margin-top: 0;
}

a {
  text-decoration: none;
  color: #000;
}

.loading {
  position: relative;
  min-height: 100px;
}

.loading > * {
  opacity: 0.1;
  -webkit-transition: all 0.30s ease-in-out;
  transition: all 0.30s ease-in-out;
}

.loading:before {
  position: absolute;
  content: "";
  left: 50%;
  top: 60px;
  margin-left: -30px;
  margin-top: -30px;
  border: 3px solid #fff;
  border-radius: 50%;
  border-top: 3px solid #007534;
  border-bottom: 3px solid #007534;
  width: 60px;
  height: 60px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  opacity: 1 !important;
  -webkit-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
  z-index: 2;
}

.loading::after {
  position: absolute;
  content: "Trwa ładowanie danych";
  left: 0px;
  top: 80px;
  text-align: center;
  width: 100%;
  margin-top: 30px;
  opacity: 1 !important;
  color: rgb(100, 100, 100);

}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.search .row-nice__input {
  border-radius: 100px;
}

input[type="text"], input[type="password"], input[type="number"], input[type="date"], textarea {
  background: #fff;
  border: 1px solid #d2d2d2;
  padding: 20px;
  color: #000;
  width: 100%;
  outline: none;
  font-size: 1rem;
  line-height: 26px;

  @media screen and (max-width: 1200px) {
    font-size: calc(0.7rem + (1 * 15 - 0.7 * 15) * ((100vw - 320px) / (1200 - 320)));
    padding: calc(10px + (20 - 10) * ((100vw - 320px) / (1200 - 320))) calc(15px + (20 - 15) * ((100vw - 320px) / (1200 - 320)));
  }

  &:focus {
    border: 1px solid #007534;
  }

  &::-webkit-input-placeholder {
    font-size: 1rem;

    @media screen and (max-width: 1200px) {
      font-size: calc(0.7rem + (1 * 15 - 0.7 * 15) * ((100vw - 320px) / (1200 - 320)));
    }
  }
}

textarea {
  padding: 15px;
  height: 100px;
  resize: vertical;
  font-family: "Outfit", sans-serif;
}

.row-nice {
  width: 100%;
  display: flex;
  flex-direction: column;

  &--rounded > input[type="text"] {
    border: none;
    border-radius: 0px;
    padding: 15px 20px;
    font-size: 1.07rem;
    line-height: 24px;

    @media screen and (max-width: 1200px) {
      font-size: calc(0.7rem + (1.07 * 15 - 0.7 * 15) * ((100vw - 320px) / (1200 - 320)));
      padding: calc(10px + (20 - 10) * ((100vw - 320px) / (1200 - 320))) calc(15px + (20 - 15) * ((100vw - 320px) / (1200 - 320)));
    }

    &:focus {
      border: none;
    }
  }

  &--icon > input[type="text"] {

  }
}

select {
  background: #fff;
  border: 1px solid #d2d2d2;
  padding: 20px 56px 20px 20px;
  color: #333;
  width: 100%;
  outline: none;
  font-size: 1rem;
  line-height: 26px;

  @media screen and (max-width: 1200px) {
    font-size: calc(0.7rem + (1 * 15 - 0.7 * 15) * ((100vw - 320px) / (1200 - 320)));
    padding: calc(10px + (20 - 10) * ((100vw - 320px) / (1200 - 320))) calc(15px + (20 - 15) * ((100vw - 320px) / (1200 - 320)));
  }

  &:focus {
    border: 1px solid #007534;
  }

  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}

option {
  color: #333;

  &:disabled {
    color: #333;
  }
}

.my-select {
  width: 100%;
  position: relative;

  & .material-symbols-outlined {
    color: #333;
    position: absolute;
    top: calc(50% - 12px);
    right: 20px;
    pointer-events: none;
  }

  &:has(select:focus) .material-symbols-outlined {
    transform: rotate(180deg);
  }
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: -webkit-linear-gradient(0deg, #007534, #007534);
  border-radius: 0;
  box-shadow: 2.5px 4.33px 15px 0 rgba(0, 0, 0, .15);
  font-size: 1rem;
  color: #fff;
  padding: 20px 30px;
  text-transform: uppercase;
  line-height: normal;
  transition: all 0.25s ease 0s;
  cursor: pointer;

  @media screen and (max-width: 1200px) {
    font-size: calc(0.7rem + (1 * 15 - 0.7 * 15) * ((100vw - 320px) / (1200 - 320)));
    padding: calc(15px + (20 - 15) * ((100vw - 320px) / (1200 - 320))) calc(20px + (30 - 20) * ((100vw - 320px) / (1200 - 320)));
  }

  @media screen and (max-width: 991px) {
    width: 300px;
    max-width: 100%;
  }

  &--light {
    padding: 18px 30px;
    background: #fff;
    border: 2px solid #007534;
    color: #007534;

    @media screen and (max-width: 1200px) {
      padding: calc(14px + (18 - 14) * ((100vw - 320px) / (1200 - 320))) calc(20px + (30 - 20) * ((100vw - 320px) / (1200 - 320)));
    }

    &:hover {
      box-shadow: none;
      background: #fff !important;
    }
  }

  &:hover {
    background-image: -webkit-linear-gradient(0deg, #007534, #007534);
  }
}

html.contrast {

  & input[type="text"], & textarea, & input[type="number"], & input[type="date"] {
    background-color: #222;
    border: 1px solid #fff;
    color: #fff;

    &:focus {
      border: 1px solid #fff;
    }
  }

  & select {
    background-color: #222;
    border: 1px solid #fff;
    color: #fff;

    &:focus {
      border: 1px solid #fff;
    }

    & option {
      color: #fff;

      &:disabled {
        color: #999;
      }
    }
  }

  & .btn {
    background-image: -webkit-linear-gradient(0deg, gold 0%, #ffe574 100%);
    color: #000;

    &:hover {
      background-image: -webkit-linear-gradient(0deg, #ffe574 0%, gold 100%);
    }

    &--light {
      background: #000;
      color: gold;
      border-color: gold;

      &:hover {
        color: #fff !important;
        background: #000 !important;
      }
    }
  }
}

.loader {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 4px solid;
  border-color: rgba(100, 100, 100, 0.15) rgba(100, 100, 100, 0.25) rgba(100, 100, 100, 0.35) rgba(100, 100, 100, 0.5);
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/**
 * end file style.scss
 */

.map-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, rgba(0, 128, 0, 0.8) 0%, rgba(0, 0, 0, 0.1) 0);
  z-index: 2;
}

#map .leaflet-div-icon {
  width: 0;
  height: 0;
  border: 0;
  padding: 0;
  margin: 0;
  background-color: transparent;

  & .custom-marker {
    border-radius: 50% 50% 50% 0;
    border: 1px solid #fff;
    width: 30px;
    height: 30px;
    transform: rotate(-45deg);

    margin-top: -40px;
    margin-left: -15px;

    display: flex;
    align-items: center;
    justify-content: center;

    & > .material-symbols-outlined {
      font-size: 18px;
      font-weight: 300;
      color: #fff;
      transform: rotate(45deg);
    }
  }
}

.leaflet-container {

  &:after {
    font-family: "Lato", sans-serif !important;
    font-size: 16px !important;
    z-index: 1001 !important;
  }

  &.leaflet-gesture-handling-scroll-warning {

    & + .map__filter {

      &:after {

      }
    }
  }
}

.article__part, .event__part {

  & ul, & ol {
    margin-left: 30px;
  }

  & img {
    width: 100%;
  }
}

.module__text {

  & ul {
    padding-left: 35px;
  }
}

/**
 * google material symbols
 */
/* fallback */
@font-face {
  font-family: 'Material Symbols Outlined';
  font-style: normal;
  font-weight: 100 700;
  src: url("@/assets/google-symbols/material-symbols-outlined.woff2") format('woff2');
}

.material-symbols-outlined {
  font-family: 'Material Symbols Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

.row-nice-error .row-nice__help{
  color: red;

}

/**
 * end google materials symbols
 */

/**
 * google font
 */
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("@/assets/google-fonts/fw100.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("@/assets/google-fonts/fw300.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("@/assets/google-fonts/fw400.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("@/assets/google-fonts/fw500.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/**
 * end google font
 */

</style>