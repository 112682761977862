<template>

    <div class="context-menu" :class="{'context-menu--show': show}">
        <div class="context-menu__content" @click.stop>
            <slot name="default"/>
        </div>
    </div>

    <span v-if="show" class="full-background"></span>

</template>

<script>
export default {
    name: "ContextMenu",
    props: {
        show: {
            default() {
                return false
            }
        }
    }
}
</script>

<style scoped lang="scss">

.context-menu {
    width: max-content;
    height: max-content;
    position: absolute;
    top: 70px;
    right: 0;
    background-color: #fff;
    box-shadow: 0 5px 20px 0 rgb(0 0 0 / 10%);
    opacity: 0;
    visibility: hidden;
    transform: scaleY(0);
    transform-origin: top;
    z-index: 11;

    user-select: none;

    &__content {
		cursor: auto;
    }

    @media screen and (max-width: 767px) {
        top: 50px;
    }

    @media screen and (max-width: 444px) {
        width: 100%;
        max-height: 250px;
        overflow: auto;
        position: fixed;
        top: unset;
        bottom: 0;
        left: 0;
        right: 0;
        transform: translateY(100%);
    }

    &--show {
        opacity: 1;
        visibility: visible;
        transform: scaleY(1);

        @media screen and (max-width: 444px) {
            transform: translateY(0);
        }
    }
}

.full-background {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.0);
    z-index: 10;
    cursor: auto;

    @media screen and (max-width: 444px) {
        background-color: rgba(0, 0, 0, 0.1);
    }
}

html.contrast {

}

</style>