<template>
  <input class="input-range" :class="this.class" :style="this.style"
         :min="min" :max="max" :step="step"
         type="range"
         v-model="value_local"
         @change="updateValue"
         ref="input"
         @input="inputEvent"
         oninput="this.nextElementSibling.value = this.value">
  <output v-if="show_value" class="value_output">{{ value_local }}</output>
</template>

<script>
export default {
  name: "InputRange",
  props: {
    name: {},
    value: {},
    class: {
      default() {
        return 'row-nice__range';
      }
    },
    style: {
      default() {
        return '';
      }
    },
    min: {
      default() {
        return 0;
      }
    },
    max: {
      default() {
        return 100;
      }
    },
    step: {
      default() {
        return 1;
      }
    },
  },
  data: () => ({
    value_local: null,
    show_value: false,
    counter: 0
  }),
  methods: {
    updateValue() {
      this.$emit('updateValue', this.name, this.value_local);
    },
    inputEvent() {
      if (this.counter > 1) return;
      this.show_value = true;
      this.counter++;
      setTimeout(()=>{
        this.show_value = false;
        this.counter--;
      },1500)
    }
  },

  mounted() {
    this.value_local = this.value;
  },
  watch: {

    value(newValue, oldValue) {

      this.value_local = newValue;

    },
  },
}
</script>

<style scoped lang="scss">
.input-range {
  padding-left: 0 !important;
  padding-right: 0 !important;

  &__name {
    float: left;
    margin: 12px;
  }
}

.value_output{
  margin-top: -7px;
  position: absolute;
  left: 216px;
}
</style>