<script>
import axios from "@/axios";

export default {
  name: "TextItems",

  data: () => ({
    category_get_url: 'article/get',
    category_get_params: {
      scope: 'id,title,id_category,',
      version: 'no',
      id_top_NULL: true,
      sort: 'title'
    },
  }),

  methods: {

    async loadCategorys(id_category) {


      let url = this.category_get_url;

      let params = {...this.category_get_params};
      params['id_category'] = id_category;

      let ret = await axios.get(url, {params: params}).then(r => r.data.data);

      return ret.filter(el => el.id_category == id_category)
    }

  }
}
</script>