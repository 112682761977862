<template>
    <input
        :class="this.class"
        :style="this.style"
        :min="min"
        :max="max"
        ref="input"
        :step="step"
        type="number"
        class="input"
        v-model="value_local"
        @change="updateValue"
        :placeholder="placeholder"/>
    <span class="currency">{{ currency }}</span>

</template>

<script>
import InputBase from "./InputBase";

export default {
  name: "InputPrice",
  extends: InputBase,
  props: {
    name: {},
    placeholder: {},
    value: {},
    class: {
      default() {
        return 'row-nice__input';
      }
    },
    style: {
      default() {
        return '';
      }
    },
    min: {
      default() {
        return 0;
      }
    },
    max: {
      default() {
        return undefined;
      }
    },
    step: {
      default() {
        return 0.01;
      }
    },
    currency: {
      default() {
        return 'zł';
      }
    },
  },

  data: () => ({
    value_local: null,
  }),
  methods: {
    val() {
      return this.value_local;
    },
    focus() {
      this.$refs['input'].focus()
    },
    updateValue() {
      this.$emit('updateValue', this.name, this.value_local.toFixed(2));
    }
  },

  mounted() {
    this.value_local = this.value;
  },
  watch: {

    value(newValue, oldValue) {

      this.value_local = newValue;

    },
  },
}
</script>

<style scoped lang="scss">
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: button;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {

  //opacity: 1;

}

.placeholder-white {
  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: white;
  }

  &::-ms-input-placeholder { /* Microsoft Edge */
    color: white;
  }
}

.input{
  width: 97%;
}

.currency {
  display: inline;
  position: absolute;
  top: 12px;
  color: #000;
  font-weight: 500;
  font-size: 17px;
  padding-left: 1.3%;

}
</style>