<template>

    <ul>
        <li v-if="items" v-for="item in currentItems">
            <router-link :to="`${item.link ? item.link : '/'}`">{{ item.title }}</router-link>
            <MenuShow
                    v-if="isHasChildren(item)"
                    :items="items"
                    :id_top_position="item.id"
                    type="sub"/>

        </li>
    </ul>

</template>

<script>
import View from "@/views/View.vue"

export default {
    name: "MenuShow",
    props: {
        items: {
            default() {
                return null
            }
        },
        type: {
            default() {
                return "main"
            }
        },
        id_top_position: {
            default() {
                return null
            }
        },
        is_main: {
            default() {
                return false
            }
        }
    },
    extends: View,
    methods: {
        /**
         * Sprawdza czy element menu zawiera podelementy
         * @param item element menu
         */
        isHasChildren(item) {
            return this.items.find(obj => obj.id_top_position === item.id)
        },
    },
    computed: {
        /**
         * Zwraca aktualne elementy menu
         */
        currentItems() {
            return this.items.filter(item => item.id_top_position === this.id_top_position)
        }
    }
}
</script>

<style scoped lang="scss">

</style>