<template>

  <header class="header header--top-position">
    <div class="header__container">
      <div class="header__content">

        <router-link to="/" class="header__logo">
          <img src="@/assets/logo.png" alt="Małopolskie Centrum Edukacji Ekologicznej">
        </router-link>

        <div class="header__menu">
          <Menu :items="menu_items" :is_main="isMain"/>
        </div>

        <div class="header__plugins">
          <div class="header__plugins-wcag">
            <WCAG/>
          </div>
          <div class="header__plugins-login">
            <Login v-if="!info"/>
            <ProfileButton v-if="info"/>
          </div>
          <div class="header__plugins-menu-mobile-btn">
            <div class="menu-mobile-btn">
                            <span class="material-symbols-outlined"
                                  @click="menuMobileWrapperHandler('open')">menu</span>
            </div>
          </div>
        </div>

      </div>
    </div>
  </header>

  <MenuMobileWrapper
      :show="mobile_menu_show"
      :menu_items="menu_items"
      @emitMenuMobileWrapper="menuMobileWrapperHandler"/>

</template>

<script>
import {mapState, mapActions} from 'vuex';
import axios from "@/axios"
import Menu from "@/views/Components/Menu.vue";
import WCAG from "@/views/Components/WCAG.vue";
import Login from "@/views/Components/Login.vue";
import MenuMobileWrapper from "@/views/Components/MenuMobileWrapper.vue";
import BackToTop from "@/views/Components/BackToTop.vue";
import MenuShow from "@/views/Components/MenuShow.vue";
import ProfileButton from "@/views/Profile/Part/Button.vue";
import TextItems from "@/views/Text/Part/Items.vue";

export default {
  name: "Header",
  components: {
    ProfileButton,
    MenuShow,
    MenuMobileWrapper,
    Login,
    WCAG,
    Menu
  },
  mixins: [BackToTop,TextItems],
  data: () => ({
    menu_items: null,
    mobile_menu_show: false,
  }),
  computed: {
    isMain() {
      return this.$route.name === 'HomeShow'
    },

    /**
     * Dołączanie zmiennych z VUEX'a
     */
    ...mapState({
      token: state => state.user.token,
      token_refresh: state => state.user.token_refresh,
      token_expire: state => state.user.token_expire,
      info: state => state.user.info,
    })
  },
  methods: {
    /**
     * Dołączanie metod z VUEX'a
     */
    ...mapActions({
      refreshToken: 'user/refreshToken',
      logout: 'user/logout',
      userdelete: 'user/userdelete'
    }),

    /**
     * Obsługa kliknięć otrzymanych z komponentu "MenuMobileWrapper"
     */
    menuMobileWrapperHandler(func) {

      let scrollbarWidth = window.innerWidth - document.querySelector("body").offsetWidth + "px"

      if (func === "open") {
        document.querySelector(".header").style.transition = "none"
        document.querySelector(".header").style.paddingRight = scrollbarWidth
        document.querySelector("body").style.paddingRight = scrollbarWidth
        document.body.style.overflow = "hidden"
        this.mobile_menu_show = true
      }
      if (func === "close") {
        document.querySelector(".header").style.paddingRight = "0px"
        document.querySelector("body").style.paddingRight = "0px"
        setTimeout(() => {
          document.querySelector(".header").style.transition = "all 0.25s ease 0s"
        }, 250)
        document.body.style.overflow = "auto"
        this.mobile_menu_show = false
      }
    },

    /**
     * Pobieranie elementów menu
     */
    async loadMenuItems() {

      let params = {
        code: "main"
      }

      /**
       * Do usunięcia
       */
      this.menu_items = [
        {
          id: 1006,
          id_top_position: null,
          title: "O MCEE",
          link: "/about"
        },
        {
          id: 1003,
          id_top_position: null,
          title: "Atrakcje",
          link: "/places"
        },
        {
          id: 8,
          id_top_position: null,
          title: "Pogórze Ciężkowickie",
          link: "/education"
        },
        {
          id: 1004,
          id_top_position: null,
          title: "Galerie",
          link: "/galleries"
        },
        // {
        //   id: 1001,
        //   id_top_position: null,
        //   title: "Aktualności",
        //   link: "/articles"
        // },



        {
          id: 10034,
          id_top_position: 1003,
          title: "Atrakcje turystyczne",
          link: "/text/227252"
        },
        {
          id: 10033,
          id_top_position: 1003,
          title: "Zabytki i architektura",
          link: "/text/227250"
        },
        {
          id: 10035,
          id_top_position: 1003,
          title: "Przewodniki",
          link: "/text/227341"
        },
        {
          id: 10031,
          id_top_position: 1003,
          title: "Informacje praktyczne",
          link: "/places"
        },
        {
          id: 10032,
          id_top_position: 1003,
          title: "Mapa",
          link: "/map"
        },



        {
          id: 11,
          id_top_position: 1006,
          title: "O MCEE",
          link: "/about"
        },
        {
          id: 10061,
          id_top_position: 1006,
          title: "Oferta",
          link: "/text/227275"
        },
        {
          id: 11,
          id_top_position: 1006,
          title: "Kontakt",
          link: "/contact"
        },
        {
          id: 9,
          id_top_position: null,
          title: "Edukacja",
          link: "/"
        },
        {
          id: 555,
          id_top_position: 9,
          title: "Zaplanuj lekcję",
          link: "/lessons/modules"
        },
        {
          id: 555,
          id_top_position: 9,
          title: "Scenariusze lekcji",
          link: "/lessons"
        },
        {
          id: 556,
          id_top_position: 9,
          title: "Plany zajęciowe",
          link: "/lessons/plans"
        },
        {
          id_top_position: 9,
          title: "Gry i zabawy On-Line",
          link: "/games"
        },
        {
          id_top_position: 9,
          title: "Dobre praktyki",
          link: "/text/227271"
        },
        {
          id_top_position: 9,
          title: "Sztuka i ekologia",
          link: "/text/227273"
        },
        {
          id_top_position: 9,
          title: "Placówki edukacyjne",
          link: "/text/227267"
        },
      ]

      await axios
          .get("/lessonmodule/typeget")
          .then(response => {

            let data = response.data.data

          })

      this.menu_items = [
        ...this.menu_items
      ]

      if (this.info && this.info.type === 4){

        this.menu_items.push({
              id: 557,
              id_top_position: 9,
              title: "Lekcje do akceptacji",
              link: "/lessons/accept"
            });

        this.menu_items.push({
          id: 558,
              id_top_position: 9,
            title: "Moduły do akceptacji",
            link: "/lessons/modules/accept"
        });

      }


      /**
       * Pogórz
       */
      let info = await this.loadCategorys(38);
      info.forEach(el => {
        this.menu_items.push(
            {
              id: el.id,
              id_top_position: 8,
              title: el.title,
              link: "/text/"+el.id
            })
      })

      // let tourist = await this.loadCategorys(39);
      // tourist.forEach(el => {
      //   this.menu_items.push(
      //       {
      //         id: el.id,
      //         id_top_position: 1003,
      //         title: el.title,
      //         link: "/text/"+el.id
      //       })
      // })


      // let about = await this.loadCategorys(43);
      // about.forEach(el => {
      //   this.menu_items.push(
      //       {
      //         id: el.id,
      //         id_top_position: 1006,
      //         title: el.title,
      //         link: "/text/"+el.id
      //       })
      // })

    },
  },
  mounted() {
    this.loadMenuItems()

    let prevScrollpos = window.pageYOffset;
    let header = document.querySelector("header")

    let self = this
    window.onscroll = function () {
      let currentScrollPos = window.pageYOffset;

      // only top position
      if (currentScrollPos === 0) {
        header.classList.remove("header--first-scroll", "header--show", "header--hide")
        header.classList.add("header--top-position")
      }

      // first scroll
      if (prevScrollpos < currentScrollPos && currentScrollPos < 120) {
        header.classList.remove("header--top-position", "header--show", "header--hide")
        header.classList.add("header--first-scroll")
      }

      // show header
      if (prevScrollpos > currentScrollPos && currentScrollPos >= 120) {
        header.classList.remove("header--top-position", "header--first-scroll", "header--hide")
        header.classList.add("header--show")
      }

      // hide header
      if (prevScrollpos < currentScrollPos && currentScrollPos >= 120) {
        header.classList.remove("header--top-position", "header--first-scroll", "header--show")
        header.classList.add("header--hide")
      }

      prevScrollpos = currentScrollPos;

      // back to top
      self.scrollFunction()
    }
  }
}
</script>

<style scoped lang="scss">

.header {
  position: fixed;
  width: 100%;
  height: 86px;
  top: 0;
  left: 0;
  background-color: #fff;
  box-shadow: 0 10px 15px rgba(25, 25, 25, .1);
  transition: all 0.25s ease 0s;
  z-index: 9;

  @media screen and (max-width: 767px) {
    height: 70px;
  }

  &--main {
    background-color: rgba(0, 0, 0, 0.7) !important;

  }

  &--top-position {
    background-color: white;
    box-shadow: none;
    height: 100px;

    @media screen and (max-width: 767px) {
      height: 86px;
    }
  }

  &--first-scroll {
    height: 86px;

    @media screen and (max-width: 767px) {
      height: 70px;
    }
  }

  &--show {
    top: 0;
  }

  &--hide {
    top: -86px;

    @media screen and (max-width: 767px) {
      top: -70px;
    }
  }

  &__container {
    height: 100%;
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 15px;
  }

  &__content {
    height: inherit;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 16px;
  }

  &__logo {
    height: 100%;
    display: flex;
    align-items: center;
    width: 270px;

    padding: 20px 0;

    @media screen and (max-width: 1200px) {
      width: 130px;
    }

    & img {
      width: 100%;
    }
  }

  &__menu {
    margin-left: auto;
    margin-right: 20px;
    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  &__plugins {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 10px;

    @media screen and (max-width: 1200px) {
      column-gap: 15px;
    }

    &-search {
      display: flex;
      align-items: center;
      cursor: pointer;

      padding: 0 10px;

      @media screen and (max-width: 767px) {
        display: none;
      }

      & .material-symbols-outlined {
        font-size: 1.6rem;
      }
    }

    &-wcag {

      @media screen and (max-width: 767px) {

      }
    }

    &-login {

      @media screen and (max-width: 767px) {

      }
    }

    &-menu-mobile-btn {

    }
  }
}

.menu-mobile-btn {
  display: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media screen and (max-width: 767px) {
    display: flex;
  }

  & .material-symbols-outlined {
    font-size: 1.6rem;
  }
}

html.contrast {

  & .header {
    background-color: #000;

    &--top-position {
      background-color: transparent;
    }

    &__plugins {

      &-search {

        & .material-symbols-outlined {
          color: #fff;
        }
      }
    }
  }

  & .menu-mobile-btn {
    color: #fff;
  }
}

</style>