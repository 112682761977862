import { createStore } from 'vuex'

import user from '@/store/user';
import {app} from '@/store/app';

export default createStore({
  modules: {
    user: user,
    app: app,
  }
})
