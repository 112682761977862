<template>

  <div class="footer-top">
    <div class="footer-top__container">
      <div class="footer-top__content">
        <div class="footer-top__text">
          <h2>Pobierz aktualizację z biuletynu</h2>
        </div>
        <div class="footer-top__form">
          <Row class="row-nice--rounded" type="text" name="email" :value="email" placeholder="Adres e-mail..."
               @updateValue="updateValue"/>
          <div class="btn" @click="subscribe">Zapisz się teraz</div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import Row from "@/Library/Forms/Row.vue";

export default {
  name: "FooterTop",
  components: {
    Row
  },
  data: () => ({
    email: null,
  }),
  methods: {
    updateValue(name, value) {
      this.$data[name] = value
      this.subscribe()
    },

    subscribe() {
      alert("Powodzenie! Zostałeś zapisany na e-mail: " + this.email)
    }
  }
}
</script>

<style scoped lang="scss">

.footer-top {
  background-color: #010f2e;

  display: none;

  &__container {
    max-width: 1140px;
    margin: 0 auto;
    padding: 70px 15px 50px 15px;

    @media screen and (max-width: 1200px) {
      flex-direction: column;
      padding: calc(35px + (70 - 35) * ((100vw - 320px) / (1200 - 320))) 15px calc(25px + (50 - 25) * ((100vw - 320px) / (1200 - 320))) 15px;
    }
  }

  &__content {
    padding-bottom: 90px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
    border-bottom: 1px dashed #FFFFFF7A;

    @media screen and (max-width: 1200px) {
      flex-direction: column;
      padding-bottom: calc(45px + (90 - 45) * ((100vw - 320px) / (1200 - 320)));
    }
  }

  &__text {
    width: 50%;

    @media screen and (max-width: 1200px) {
      width: 100%;
      text-align: center;
    }

    & > h2 {
      font-size: 4rem;
      font-family: 'Unbounded';

      line-height: 72px;
      color: #fff;

      @media screen and (max-width: 1200px) {
        font-size: calc(2rem + (4 * 15 - 2 * 15) * ((100vw - 320px) / (1200 - 320)));
        line-height: calc(36px + (72 - 36) * ((100vw - 320px) / (1200 - 320)));
      }
    }
  }

  &__form {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;

    @media screen and (max-width: 1200px) {
      width: 70%;
    }

    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }
}

.btn {
  border-radius: 0;
  background-image: -webkit-linear-gradient(0deg, #007534 0%, #007534 100%);
  color: #fff;
  min-width: max-content;
  font-size: 1.07rem;
  padding: 15px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  cursor: pointer;

  @media screen and (max-width: 1200px) {
    font-size: calc(0.7rem + (1.07 * 15 - 0.7 * 15) * ((100vw - 320px) / (1200 - 320)));
    padding: calc(10px + (15 - 10) * ((100vw - 320px) / (1200 - 320))) calc(20px + (30 - 20) * ((100vw - 320px) / (1200 - 320)));
  }
}

html.contrast {

  & .footer-top {
    background-color: #000;
  }

  & .btn {
    background-image: -webkit-linear-gradient(0deg, gold 0%, gold 100%);
    color: #000;
  }
}

</style>