<template>

    <footer class="footer">


        <FooterMiddle/>

        <FooterBottom/>

    </footer>

</template>

<script>
import FooterTop from "@/views/Components/FooterTop.vue";
import FooterMiddle from "@/views/Components/FooterMiddle.vue";
import FooterBottom from "@/views/Components/FooterBottom.vue";

export default {
    name: "Footer",
    components: {FooterBottom, FooterMiddle, FooterTop}
}
</script>

<style scoped lang="scss">

.footer {
    margin-top: auto;
}

</style>