<template>

  <TemplateModal ref="modal" title="Przypomnienie hasła">
    <template #default>

      <AuthRecoverForm
        :recover_data="recover_data"
        @recoverSubmit="recoverSubmit"
        @emitLogin="$emit('emitLogin')"/>

    </template>
  </TemplateModal>

</template>

<script>
import TemplateModal from "@/views/Components/TemplateModal.vue";
import AuthRecoverForm from "@/views/Auth/Part/RecoverForm.vue";
import ComponentFormStateMachine from "@/Library/Extends/ComponentFormStateMachine";
import { useToast } from "vue-toastification";
import axios from "@/axios";
import View from "@/views/View.vue"

export default {
  name: "AuthRecoverModal",
  components: {
    AuthRecoverForm,
    TemplateModal
  },
  extends: View,
  emits: ["emitLogin"],
  mixins: [ComponentFormStateMachine],
  data: () => ({
    recover_data: {
      mail: null,
    }
  }),
  methods: {
    open() {
      this.$refs.modal.open()
    },

    close() {
      this.$refs.modal.close()
    },

    recoverSubmit() {

      //this.stateUpdate('sendInfo');

      const toast = useToast();

      let data = this.recover_data

      axios
        .post('user/recover', data)
        .then(response => {

          // toast.success("");

          alert('Link aktywacyjny został wysłany na podany adres mail');

          //this.stateUpdate('sendSuccess');

          this.close();

          router.push('/login');

        })
        .catch(error => {

          //this.stateUpdate('sendWrong');

          let error_data = error.response.data;
          let error_status = error.response.status;
          let error_header = error.response.headers;
          let error_code = error_data.code;

          console.log(error_data);
          console.log(error_status);
          console.log(error_header);

          switch (error_code) {
            case 'no-required-data':
              this.errorMessage = 'Brak wymaganych danych.'
              break;
            case 'object-no-exist':
              this.errorMessage = 'Błędne dane'
              break;
            default:
              this.errorMessage = 'Błędne dane'
              break;
          }


          toast.error(this.errorMessage, {
            timeout: 2000
          });

        })

    }
  }
}
</script>

<style scoped lang="scss">

</style>