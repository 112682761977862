<template>
  <div class="input-color" :class="{'input-color-active': isActive}">

    <input class="input-color__text row-nice__input" type="text" v-model="value_local"
           @keyup="updateValue" @change="updateValue"
           @focus="input_focus = true" @blur="input_focus = false"/>
    <input class="input-color__dot" type="color" v-model="value_local" @change="updateValue"/>
  </div>
</template>

<script>
import Input from "@/Library/Forms/InputBase";

export default {
  name: "InputColor",
  extends: Input,
  props: {
    value: {
      default() {
        return "";
      }
    }
  },

  data: () => ({
    input_focus: false,
    value_local: null,
  }),

  computed: {
    isActive() {
      return (this.input_focus || this.value_local !== "");
    }
  },

  mounted() {
    this.value_local = this.value;
  },

  methods: {
    val() {
      return this.value_local;
    },
    updateValue() {
      this.$emit('updateValue', this.name, this.value_local);
    }
  },
  watch: {

    value(newValue, oldValue) {

      this.value_local = newValue;
      // console.log(newValue, this.value_show)

    },
  }
}
</script>

<style scoped lang="scss">
.input-color {
  &__dot {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: absolute;
    top: 10px;
    right: 15px;

    border: none;
    cursor: pointer;

    &::-webkit-color-swatch {
      padding: 0;
      border: none;
      border-radius: 50%;
    }

    &::-webkit-color-swatch-wrapper {
      padding: 0;
      border: none;
      border-radius: 50%;
    }
  }
}
</style>