<template>

    <ul class="menu" :class="{'menu--main': type === 'main', 'menu--sub': type === 'sub'}">
        <li class="menu__item item" v-if="items" v-for="item in currentItems">


            <router-link :to="`${item.link ? item.link : '/'}`">{{ item.title }}</router-link>

            <Menu
                    v-if="isHasChildren(item)"
                    :items="items"
                    :id_top_position="item.id"
                    type="sub"/>

        </li>
    </ul>

</template>

<script>
import View from "@/views/View.vue"

export default {
    name: "Menu",
    props: {
        items: {
            default() {
                return null
            }
        },
        type: {
            default() {
                return "main"
            }
        },
        id_top_position: {
            default() {
                return null
            }
        },
        is_main: {
            default() {
                return false
            }
        }
    },
    extends: View,
    methods: {
        /**
         * Sprawdza czy element menu zawiera podelementy
         * @param item element menu
         */
        isHasChildren(item) {
            return this.items.find(obj => obj.id_top_position === item.id)
        },
    },
    computed: {
        /**
         * Zwraca aktualne elementy menu
         */
        currentItems() {
            return this.items.filter(item => item.id_top_position === this.id_top_position)
        }
    }
}
</script>

<style scoped lang="scss">

.menu {
    list-style: none;
    display: flex;
    flex-direction: row;
    column-gap: 40px;
    height: 100%;

    @media screen and (max-width: 1200px) {
        column-gap: 15px;
    }

    &--sub {
        display: block;
        width: 350px;
        height: fit-content;
        position: absolute;
        top: 90%;
        left: -20%;
        opacity: 0;
        visibility: hidden;
        background-color: #fff;
        border-top: 4px solid #007534;
        box-shadow: 0 10px 15px rgba(25,25,25,.1);

        & > .menu__item {
            border-bottom: 1px solid #ddd;

            & > a {
                display: block;
                padding: 15px;
            }

          &:last-child {
            border-bottom: none;
          }
        }
    }

    &__item {
        position: relative;

        & > a {
            font-size: 1.07rem;
            font-weight: 580;
            height: 100%;
            color: #4f4f4f;
            display: flex;
            align-items: center;
            cursor: pointer;
            transition: all 0.25s ease 0s;
        }

        &:hover {


            & > a {
                color: #007534;
            }

            & > .menu--sub {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

.item{
    &--main > a{
        color: #fff;
    }
}

html.contrast {

    & .menu {

        &--sub {
            background-color: #000;
            border-top-color: gold;
            border-left: 1px solid gold;
            border-right: 1px solid gold;
            border-bottom: 1px solid gold;
        }

        &__item {
            & > a {
                color: #fff;
            }

            &:hover {


                & > a {
                    color: gold;
                }
            }
        }
    }
}


</style>