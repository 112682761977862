<template>
  <span class="percent" :class="this.class">
    <span v-if="percent !== undefined && percent !== null" style="display: inline-block">
      {{ percent_show }}
      <span class="percent__suffix grey-text">
        {{ suffix_show }}
      </span>
    </span>
    <span v-if="percent === null || percent === undefined" class="grey-text">
      {{ percent_show }}
    </span>
  </span>
</template>

<script>

export default {
  name: "ShowPercent",
  props: {
    percent: {
      default() {
        return undefined;
      }
    },
    class: {
      default() {
        return {};
      }
    },
    show_null: {
      default() {
        return true;
      }
    },
    show_sufix: {
      default() {
        return true;
      }
    },
    null_text: {
      default() {
        return "Brak";
      }
    },
    suffix: {
      default() {
        return ' %';
      }
    },
  },
  methods: {
    format(val) {
      let format = new Intl.NumberFormat(undefined, {
        minimumIntegerDigits: 1,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
      let num = val.toString();
      const comma = num.indexOf(',');
      
      if (comma === -1) 
        return format.format(parseFloat(num));
      
      if (comma > -1) 
        num.replace(',', '.')
      
      return format.format(parseFloat(num));

    }
  },
  computed: {
    percent_show() {
      let ret = null;
      if (this.show_null)
        ret = this.null_text;

      if (this.percent !== null && this.percent !== undefined) {
        let percent = this.percent;
        ret = this.format(percent);
      }
      return ret;
    },

    suffix_show() {
      let ret = null;
      if ((this.percent !== null && this.percent !== undefined) && this.show_sufix) {
        ret = this.suffix;
      }
      return ret;
    }
  }
}
</script>

<style scoped lang="scss">

.percent {
  & {

  }
  &__suffix {
    //color: #aaa;
  }
}

</style>