<template>
    <input class="row-nice__input" type="date" v-model="value_local" :disabled="disabled"
           @keyup="updateValue" @change="updateValue"/>
</template>

<script>
import InputBase from "./InputBase";

export default {
  name: "InputDate",
  extends: InputBase,
  props: ['name','value','disabled'],
  data: () => ({
    value_local: null,
  }),
  methods: {
    val(){
      return this.value_local;
    },
    updateValue() {
      this.$emit('updateValue', this.name, this.value_local);
    }
  },
  mounted() {
    this.value_local = this.value;
  },
  watch: {

    value(newValue, oldValue) {

      this.value_local = newValue;
      // console.log(newValue, this.value_show)

    },
  }
}
</script>

<style scoped>

</style>