<template>

    <div class="btn" @click="$refs.authLoginModal.open()">
        <span class="material-symbols-outlined">Person</span>
    </div>

    <AuthLoginModal ref="authLoginModal" @emitForgotPassword="recover" @emitRegister="register"/>
    <AuthRecoverModal ref="authRecoverModal" @emitLogin="login"/>
    <AuthRegisterModal ref="authRegisterModal" @emitLogin="login"/>

</template>

<script>

import AuthLoginModal from "@/views/Auth/Part/LoginModal.vue";
import AuthRecoverModal from "@/views/Auth/Part/RecoverModal.vue";
import AuthRegisterModal from "@/views/Auth/Part/RegisterModal.vue";

export default {
    name: "Login",
    components: {
        AuthRegisterModal,
        AuthRecoverModal,
        AuthLoginModal,
    },
    data: () => ({
        context_menu_show: false,
    }),
    methods: {
        recover() {
            this.$refs.authLoginModal.close()
            setTimeout(() => {
                this.$refs.authRecoverModal.open()
            }, 250)
        },

        login() {
            this.$refs.authRecoverModal.close()
            this.$refs.authRegisterModal.close()
            setTimeout(() => {
                this.$refs.authLoginModal.open()
            }, 250)
        },

        register() {
            this.$refs.authLoginModal.close()
            setTimeout(() => {
                this.$refs.authRegisterModal.open()
            }, 250)
        },
    }
}
</script>

<style scoped lang="scss">

.btn {
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    background-image: -webkit-linear-gradient(0deg, #007534 0%, #007534 100%);
    box-shadow: 2.5px 4.33px 15px 0 rgba(0,0,0,.15);

    @media screen and (max-width: 1200px) {
        width: 48px;
        height: 48px;
    }

    @media screen and (max-width: 767px) {
        width: 40px;
        height: 40px;
    }

    & .material-symbols-outlined {
        font-size: 1.6rem;
        color: #fff;

        @media screen and (max-width: 767px) {
            font-size: 1.2rem;
        }
    }

    &:hover {
        background-image: -webkit-linear-gradient(0deg, #007534 0%, #007534 100%);
    }
}

html.contrast {

    & .btn {
        background-image: -webkit-linear-gradient( 0deg, gold 0%, gold 100%);

        & .material-symbols-outlined {
            color: #000;
        }

        &:hover {
            background-image: -webkit-linear-gradient( 0deg, gold 0%, gold 100%);
        }
    }
}

</style>