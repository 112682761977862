<template>

	<router-link to="/profile/lessons" class="profile-button">
		<span class="material-symbols-outlined">person</span>
	</router-link>

</template>

<script>
export default {
    name: "ProfileButton"
}
</script>

<style scoped lang="scss">

.profile-button {
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  background-image: -webkit-linear-gradient( 0deg, #007534 0%, #007534 100%);
  box-shadow: 2.5px 4.33px 15px 0 rgba(0,0,0,.15);
  position: relative;

  @media screen and (max-width: 1200px) {
    width: 48px;
    height: 48px;
  }

  @media screen and (max-width: 767px) {
    width: 40px;
    height: 40px;
  }

  & .material-symbols-outlined {
    font-size: 1.6rem;
    color: #fff;

    @media screen and (max-width: 767px) {
      font-size: 1.2rem;
    }
  }

  &:hover {
    background-image: -webkit-linear-gradient( 0deg, #007534 0%, #007534 100%);
  }
}

html.contrast {

  & .profile-button {
    background-image: -webkit-linear-gradient( 0deg, gold 0%, gold 100%);

    & .material-symbols-outlined {
      color: #000;
    }

    &:hover {
      background-image: -webkit-linear-gradient( 0deg, gold 0%, gold 100%);
    }
  }
}

</style>