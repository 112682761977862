import axios from "@/axios";
import router from "@/router";

const state = {
    token: localStorage.getItem('token'),
    token_refresh: localStorage.getItem('token_refresh'),
    token_expire: localStorage.getItem('token_expire'),
    info: localStorage.getItem('info') !== undefined ? JSON.parse(localStorage.getItem('info')) : null,
};

const actions = {
    async login({commit}, {data}) {

        let token = data.token;
        let token_refresh = data.token_refresh;
        let expire = data.expire;

        localStorage.setItem('token', token);
        localStorage.setItem('token_refresh', token_refresh);
        localStorage.setItem('token_expire', expire);

        commit('login', token);
        commit('token_expire', expire);
        commit('token_refresh', token_refresh);

        /**
         * Aktualizacja tokena w domyślnych ustawieniach axios'a
         */
        axios.defaults.headers['token'] = token;


    },
    async refreshToken({commit}) {

        let data_post = {
            'token_refresh': localStorage.getItem('token_refresh')
        }
        let config = {
            'header': {
                'token': localStorage.getItem('token')
            }
        }

        await axios
            .post('user/authrefresh', data_post, config)
            .then(function (response) {

                let data = response.data.data;

                let token = data.token;
                let token_refresh = data.token_refresh;
                let expire = data.expire;

                localStorage.setItem('token', token);
                localStorage.setItem('token_refresh', token_refresh);
                localStorage.setItem('token_expire', expire);

                commit('login', token);
                commit('token_refresh', token_refresh);
                commit('token_expire', expire);

                /**
                 * Aktualizacja tokena w domyślnych ustawieniach axios'a
                 */
                axios.defaults.headers['token'] = token;
            })
            .catch(function (error) {
                console.log(error.toJSON());
            });
    },
    async logout({commit}) {


        await axios
            .post('user/logout')
            .then(function (response) {


            })
            .catch(function (error) {
                console.log(error.toJSON());
            })
            .finally(function () {

                localStorage.removeItem('token');
                localStorage.removeItem('token_expire');
                localStorage.removeItem('info');
                commit('logout');

                axios.defaults.headers['token'] = null;

                router.push('/')
            })

    },
    async userdelete({commit}) {


        await axios
            .post('user/userdelete')
            .then(function (response) {


            })
            .catch(function (error) {
                console.log(error.toJSON());
            })
            .finally(function () {

                localStorage.removeItem('token');
                localStorage.removeItem('token_expire');
                localStorage.removeItem('info');
                commit('logout');

                axios.defaults.headers['token'] = null;

                router.push('/')
            })

    },
    async getUserInfo({commit}) {

        let params = {
            'by_token': localStorage.getItem('token'),
            'scope': 'id,role,name_all,avatar_url,config,system_privileges,type',
        }

        await axios
            .get('user/get', {'params': params})
            .then(response => {

                let data = response.data;
                let info = data.data[0];

                localStorage.setItem('info', JSON.stringify(info));
                commit('info', info);

            })
            .catch(error => {

                console.log(error)

                let error_data = error.response.data;
                let error_status = error.response.status;
                let error_header = error.response.headers;
                let error_code = error_data.code;

                console.log(error_data);
                console.log(error_status);
                console.log(error_header);

                switch (error_code) {
                    case 'no-required-data':
                        this.errorMessage = 'Brak wymaganych danych.'
                        break;
                    case 'no-login':
                    case 'token-expired':
                        this.errorMessage = 'Token wygasł'

                        /**
                         * Wylogowuje
                         */
                        localStorage.removeItem('token');
                        localStorage.removeItem('token_expire');
                        localStorage.removeItem('info');
                        commit('logout');

                        axios.defaults.headers['token'] = null;

                        router.push('/login');

                        break;
                }

            });
    },
    async updateAvatar({commit},value,value_show){

    }

};

const mutations = {
    login(state, token) {

        state.token = token;

    },
    token_refresh(state, token_refresh) {

        state.token_refresh = token_refresh;

    },
    token_expire(state, token_expire) {

        state.token_expire = token_expire;

    },
    logout(state) {

        state.token = null;
        state.token_expire = null;
        state.token_refresh = null;
        state.info = null;

    },
    info(state, info) {

        state.info = info;

    }
};

const getters = {
    token: state => {
        return state.token
    },

    token_expire: state => {
        return state.token_expire
    }

}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};