<template>
  <template v-if="urlType === 'url'">
    <video controls :class="this.class" ref="show" class="show-video">
      <source :src="this.video" :type="this.type">
      Your browser does not support the video tag.
    </video>
  </template>
  <template v-if="urlType === 'youtube'">
    <div class="video-container">
      <iframe
          class="video-container__video"
          :src="this.video"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
      >
      </iframe>
    </div>
  </template>
  <template v-if="urlType === 'vimeo'">
    <div class="video-container">
      <iframe
          class="video-container__video"
          title="vimeo-player" :src="this.video" width="640" height="360" frameborder="0"
          allowfullscreen></iframe>
    </div>
  </template>
</template>

<script>

export default {
  name: "ShowVideo",
  components: {},
  props: {
    video: {},
    class: {},
  },
  data() {
  },
  methods: {
    load() {
      let element = this.$refs.show;

      element.load();
    }
  },
  computed: {

    urlType() {


      let pastedData = this.video;
      let media = {
        type: 'url',
      };
      let id = null;

      if (!pastedData)
        return null;


      if (pastedData.match('https://(www.)?youtube|youtu\.be')) {

        if (pastedData.match('embed')) {
          id = pastedData.split(/embed\//)[1].split('"')[0];
        } else {
          id = pastedData.split(/v\/|v=|youtu\.be\//)[1].split(/[?&]/)[0];
        }
        media = {};
        media.type = "youtube";
        media.id = id;

      } else if (pastedData.match('https://(player.)?vimeo\.com')) {
        id = pastedData.split(/video\/|https:\/\/vimeo\.com\//)[1].split(/[?&]/)[0];
        media = {};
        media.type = "vimeo";
        media.id = id;

      } else if (pastedData.match('https://player\.soundcloud\.com')) {
        let soundcloud_url = unescape(pastedData.split(/value="/)[1].split(/["]/)[0]);

        id = soundcloud_url.split(/tracks\//)[1].split(/[&"]/)[0];

        media = {};
        media.type = "soundcloud";
        media.id = id;
      }

      return media.type;

    },

    format() {

      let filename = this.video;

      return filename ? filename.split('.').pop() : 'mp4';

    },
    type() {
      return 'video/' + this.format
    }
  }

}
</script>

<style scoped lang="scss">
.show-video {
  width: 100%;
  //height: 0;
  //padding-bottom: 67%;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;

}

.mw-600 {
  background-color: var(--sp-body-bg) !important;
  max-width: 600px;
  padding-bottom: 400px;
  border-radius: 6px;
}

.video-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;

  &__video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

  }
}

</style>