<script>

import ComponentStateMachine from "@/Library/Extends/ComponentStateMachine";

/**
 * Stan kompunentu gdy oczekuje na akcje użytkownika
 *
 * @type {string}
 */
const form_state_waiting = 'waiting';

/**
 * Stan kompunentu gdy oczekuje na odpowiedź z serwera
 * @type {string}
 */
const form_state_loading = 'loading';

/**
 * Stan kompunentu gdy serwer zwrócił błąd
 * @type {string}
 */
const form_state_error = 'error';

/**
 * Stan kompunentu gdy serwer zwrócił sukces
 * @type {string}
 */
const form_state_success = 'success';

export default {
  name: "ComponentFormStateMachine",
  extends: ComponentStateMachine,
  data() {
    return {
      errorMessage: "",
      states: {
        form_state_waiting: form_state_waiting,
        form_state_loading: form_state_loading,
        form_state_error: form_state_error,
        form_state_success: form_state_success,
      },
      state: form_state_waiting,
      state_transitions: {
        [form_state_waiting]: {
          'sendInfo': form_state_loading
        },
        [form_state_loading]: {
          'sendSuccess': form_state_success,
          'sendWrong': form_state_error
        },
        [form_state_error]: {
          'sendInfo': form_state_loading
        },
        [form_state_success]: {
          'sendInfo': form_state_loading
        }
      }
    }
  },
  methods: {

    stateLoading() {

      // console.log(this.state)
      return this.stateCompare('loading');
    },
  }
}
</script>