<template>

    <ul class="menu" :class="{'menu--main': type === 'main', 'menu--sub': type === 'sub'}">
        <li
                class="menu__item"
                :class="{'menu__item--open': item.open}"
                v-if="items"
                v-for="item in currentItems">

            <div class="menu__item-content" :style="{paddingLeft: deep * 15 + 'px'}">
                <router-link :to="item.link ? item.link : '/'" @click="emitMenuMobile(item, 'close')">{{ item.title }}</router-link>
                <span v-if="isHasChildren(item)" @click="emitMenuMobile(item, 'toggle')" class="material-symbols-outlined">{{ !item.open ? 'add' : 'remove' }}</span>
            </div>

            <MenuMobile
                    v-if="item.open"
                    :items="items"
                    :id_top_position="item.id"
                    type="sub"
                    @emitMenuMobile="emitMenuMobile"
                    :deep="deep + 1"/>
        </li>
    </ul>

</template>

<script>
import View from "@/views/View.vue"

export default {
    name: "MenuMobile",
    props: {
        items: {
            default() {
                return null
            }
        },
        type: {
            default() {
                return "main"
            }
        },
        id_top_position: {
            default() {
                return null
            }
        },
        deep: {
            default() {
                return 1
            }
        }
    },
    extends: View,
    emits: ["emitMenuMobile"],
    methods: {
        /**
         * Emitowanie kliknięć
         */
        emitMenuMobile(item, func) {
            this.$emit("emitMenuMobile", item, func)
        },

        /**
         * Sprawdza czy element menu zawiera podelementy
         * @param item element menu
         */
        isHasChildren(item) {
            return this.items.find(obj => obj.id_top_position === item.id)
        },
    },
    computed: {
        /**
         * Zwraca aktualne elementy menu
         */
        currentItems() {
            return this.items.filter(item => item.id_top_position === this.id_top_position)
        }
    }
}
</script>

<style scoped lang="scss">

.menu {
	list-style: none;

    &--sub {

        & .menu__item {

            & .menu__item-content {

                & > a, & .material-symbols-outlined {
                    color: #333;
                }
            }
        }
    }

	&__item {
		user-select: none;

		&-content {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			column-gap: 15px;
            border-bottom: 1px solid #ddd;

            & > a, & .material-symbols-outlined {
                color: #333;
                cursor: pointer;
                padding: 15px;
                transition: all 0.25s ease 0s;
            }

			& > a {
                width: 100%;
                font-size: 1.07rem;
                font-weight: 580;
			}

			& > .material-symbols-outlined {
				font-size: 1.25rem;
			}
		}

		&--open {

		}
	}
}

html.contrast {

    & .menu {

        &--sub {

            & .menu__item {

                &-content {

                    & > a, & .material-symbols-outlined {
                        color: #c2c2c2;
                    }
                }
            }
        }

        &__item {

            &-content {

                & > a, & .material-symbols-outlined {
                    color: #fff;
                }
            }
        }
    }
}

</style>