<template>

    <div class="footer-middle">
        <div class="footer-middle__container">
            <div class="footer-middle__content">
                <div>
                    <img style="max-width: 100%;"
                         src="@/assets/logo-small.png" alt="Małopolskie Centrum Edukacji Ekologicznej"

                         class="footer-middle__logo"/>
                    <p class="footer-middle__description">Portal skierowany do miłośników i entuzjastów ekologii, oraz edukacji ekologicznej w Małopolsce.</p>

                </div>
                <div class="menu menu--our-links">
                    <h2>Nasze linki</h2>
                    <ul>
                        <li v-for="item in our_links">
                            <router-link :to="item.link">{{ item.title }}</router-link>
                        </li>
                    </ul>
                </div>
                <div class="menu menu--get-in-touch">
                    <h2>Skontaktuj się z nami</h2>
                    <ul>
                        <li v-for="item in get_in_touch">
                            <span class="material-symbols-outlined">{{ item.icon }}</span>
                            <div>
                                <a :href="'tel:' + element.title" v-if="item.type === 'phone'"
                                   v-for="element in item.elements">{{ element.title }}</a>
                                <a :href="'mailto:' + element.title" v-if="item.type === 'mail'"
                                   v-for="element in item.elements">{{ element.title }}</a>
                                <span  v-if="item.type === 'info'"
                                   v-for="element in item.elements">{{ element.title }}</span>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="menu">
                    <h2>Godziny otwarcia</h2>
                    <ul>
                        <li v-for="item in open" class="hour-item">
                            <span class="hour-item__name">{{ item.name }}</span>
                            <span class="hour-item__hours">{{ item.hours }}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: "FooterMiddle",
    data: () => ({
        our_links: [
            {
                id: 1006,
                id_top_position: null,
                title: "O nas",
                link: "/about"
            },
            {
                id: 1001,
                id_top_position: 1007,
                title: "Aktualności",
                link: "/articles"
            },
            // {
            //     id: 1002,
            //     id_top_position: 1007,
            //     title: "Wydarzenia",
            //     link: "/events"
            // },
            {
                id: 1003,
                id_top_position: null,
                title: "Informacje praktyczne",
                link: "/places"
            },
            {
                id: 1003,
                id_top_position: null,
                title: "Scenariusze",
                link: "/lessons"
            },
            {
                id: 1004,
                id_top_position: 1007,
                title: "Galerie",
                link: "/galleries"
            },
            {
                id: 1005,
                id_top_position: null,
                title: "Mapa",
                link: "/map"
            },
            {
                id: 1006,
                id_top_position: 1007,
                title: "Kontakt",
                link: "/contact"
            },
        ],

        get_in_touch: [
            {
                id: 1,
                icon: "where_to_vote",
                type: "info",
                elements: [{title: "ul. Parkowa 4"}, {title: "33-190 Ciężkowice"}]
            },
            {
                id: 1,
                icon: "phone_enabled",
                type: "phone",
                elements: [{title: "+48 14 67 67 664"}, {title: "+48 14 67 67 664"}]
            },
            {
                id: 2,
                icon: "mail",
                type: "mail",
                elements: [{title: "cutik@ciezkowice.pl"}, {title: "rezerwacje@mcee.pl"}]
            }
        ],

        our_gallery: [
            {
                id: 1,
                url: "https://marekowczarz.pl/wp-content/uploads/2017/03/brzanka-liwocz-3-e1490905483568.jpg",
                link: "/"
            },
            {
                id: 2,
                url: "https://www.dusekarpat.cz/foto/hlavni/polsko/pogorze-ciezkowickie-v.jpg",
                link: "/"
            },
            {
                id: 3,
                url: "https://lh3.googleusercontent.com/Eq0thviip8Vi9dqSniBHg01Yc0rqxX7RBKm_Yi_adFtpZBb0zBfpcy-cg8eeKIYfLhBitLDlDDOwBex2RRisJyNbmO4G-e-rlEnFZu7ziJkDvXb6XDmUA_Vtsa51mhPwN1KZ4xZhyV4=w1152-h768-no",
                link: "/"
            },
            {
                id: 4,
                url: "https://lh3.googleusercontent.com/QK5vUxKiOAkZHvC0WF4Z_hqWM7MbHw5stHtogd-w7AzY12z-2Zo3Ci4vIESjT1Wi8qoFZMhZSYXjQQKzxbdNoBylKMd9ewpvkASeAzELdrcNz3KFDyVdPBTjQOdHzI0teFhkCInZDr0=w1152-h768-no",
                link: "/"
            }
        ],

        open: [
            {name: 'Poniedziałek', hours: 'Nieczynne'},
            {name: 'Wtorek', hours: '9:00 – 16:00'},
            {name: 'Środa', hours: '9:00 – 16:00'},
            {name: 'Czwartek', hours: '9:00 – 16:00'},
            {name: 'Piątek', hours: '9:00 – 16:00'},
            {name: 'Sobota', hours: '11:30 - 18:30'},
            {name: 'Niedziela', hours: '11:30 - 18:30'},
        ]

    })
}
</script>

<style scoped lang="scss">

.footer-middle {
  background-color: #010f2e;
  color: #ffffff;

  &__container {
    max-width: 1140px;
    margin: 0 auto;
  }

  &__content {
    padding: 70px 0 40px 0;
    display: flex;
    flex-direction: row;
    align-items: start;
    flex-wrap: wrap;
    font-size: 1rem;


    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 30px;

    @media screen and (max-width: 1200px) {

      &{
        display: flex;
        flex-direction: column;
      }
      & > div {

        min-width: 100%;
        margin-bottom: 30px;

        padding: 0 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      padding: calc(35px + (70 - 35) * ((100vw - 320px) / (1200 - 320))) 0 calc(20px + (40 - 20) * ((100vw - 320px) / (1200 - 320))) 0;
    }

    & > div {
      //max-width: 25%;
      //padding: 0 15px;
    }
  }

  &__logo {
    margin-bottom: 45px;
    width: 200px;
    //width: auto;

    filter: brightness(0) invert(1);

    @media screen and (max-width: 1200px) {
      margin-bottom: calc(20px + (45 - 20) * ((100vw - 320px) / (1200 - 320)));
    }
  }

  &__description {
    margin-bottom: 30px;
  }

  &__location {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;

    @media screen and (max-width: 1200px) {
      gap: calc(15px + (20 - 15) * ((100vw - 320px) / (1200 - 320)));
    }

    & .material-symbols-outlined {
      background-image: -webkit-linear-gradient(90deg, rgb(118, 194, 27) 0%, #007534 100%);
      border-radius: 50%;
      min-width: 40px;
      min-height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 1.3rem;
      font-weight: 100;

      @media screen and (max-width: 1200px) {
        font-size: calc(1.05rem + (1.33 * 15 - 1.05 * 15) * ((100vw - 320px) / (1200 - 320)));
        width: calc(30px + (40 - 30) * ((100vw - 320px) / (1200 - 320)));
        height: calc(30px + (40 - 30) * ((100vw - 320px) / (1200 - 320)));
      }
    }
  }
}

.menu {

  & > h2 {
    font-size: 1.33rem;
    line-height: 24px;
    color: #fff;
    padding-bottom: 15px;
    margin-bottom: 35px;
    position: relative;
    text-transform: capitalize;

    @media screen and (max-width: 1200px) {
      font-size: calc(1.05rem + (1.33 * 15 - 1.05 * 15) * ((100vw - 320px) / (1200 - 320)));
      padding-bottom: calc(10px + (15 - 10) * ((100vw - 320px) / (1200 - 320)));
      margin-bottom: calc(25px + (35 - 25) * ((100vw - 320px) / (1200 - 320)));
    }

    &::after {
      position: absolute;
      content: "";
      left: 0;
      bottom: 0;
      width: 80px;
      height: 1px;
      background-color: #007534;
    }
  }

  & > ul {
    list-style: none;

    & > li {

    }
  }

  &--our-links {

    & > ul {
      display: flex;
      flex-direction: row;
      gap: 15px;
      flex-wrap: wrap;

      & > li {
        width: 45%;

        @media screen and (max-width: 1200px) {
          width: auto;
        }

        & > a {
          color: #ffffff;
          transition: all 0.25s ease 0s;

          &:hover {
            color: #007534;
          }
        }
      }
    }
  }

  &--get-in-touch {

    & > ul {
      display: flex;
      flex-direction: row;
      gap: 20px;
      flex-wrap: wrap;

      & > li {
        display: flex;
        flex-direction: row;
        align-items: start;
        gap: 20px;

        @media screen and (max-width: 1200px) {
          gap: calc(15px + (20 - 15) * ((100vw - 320px) / (1200 - 320)));
        }

        & .material-symbols-outlined {
          background-image: -webkit-linear-gradient(90deg, #007534 0%, #007534 100%);
          border-radius: 50%;
          min-width: 40px;
          min-height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          font-size: 1.3rem;
          font-weight: 100;

          @media screen and (max-width: 1200px) {
            font-size: calc(1.05rem + (1.33 * 15 - 1.05 * 15) * ((100vw - 320px) / (1200 - 320)));
            width: calc(30px + (40 - 30) * ((100vw - 320px) / (1200 - 320)));
            height: calc(30px + (40 - 30) * ((100vw - 320px) / (1200 - 320)));
          }
        }

        & > div:last-child {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;

          & > a {
            color: #ffffff;
            transition: all 0.25s ease 0s;

            &:hover {
              color: #007534;
            }
          }
        }
      }
    }
  }

  &--our-gallery {

    & > ul {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      flex-wrap: wrap;

      & > li {

        & > a {
          display: block;
          width: 122px;
          height: 95px;
          border-radius: 10px;

          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;

          position: relative;
          overflow: hidden;
          cursor: pointer;

          &:hover {

            & > div:first-child {
              opacity: 0.6;
              visibility: visible;
              transform: translateY(0);
            }
          }

          & > div:first-child {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #007534;
            transform: translateY(-10px);
            transition: all 0.25s ease 0s;
            opacity: 0;
            visibility: hidden;

            & .material-symbols-outlined {
              color: #fff;
            }
          }
        }
      }
    }
  }
}

html.contrast {

  & .footer-middle {
    background-color: #000;

    &__content {
      color: #fff;
    }

    &__location {

      & .material-symbols-outlined {
        background-image: -webkit-linear-gradient(0deg, gold 0%, gold 100%);
        color: #000;
      }
    }
  }

  & .menu {
    & > h2 {

      &::after {
        background-color: gold;
      }
    }

    & a {
      color: #fff !important;

      &:hover {
        color: gold !important;
      }
    }

    &--get-in-touch {

      & .material-symbols-outlined {
        background-image: -webkit-linear-gradient(0deg, gold 0%, gold 100%);
        color: #000;
      }
    }

    &--our-gallery {

      & > ul {

        & > li {

          & > a {

            & > div:first-child {
              background-color: gold;

              & .material-symbols-outlined {
                color: #000;
              }
            }
          }
        }
      }
    }
  }
}

.hour-item {
  color: #fff;
  display: flex;
  justify-content: space-between;

  flex-direction: row;

    &__hours{
        float: right;
    }
}

</style>