<template>
  <div class="upload">
    <ShowGallery
        :gallery="files"
        :can_delete="true"
        v-on:imageDelete="remove"
    />

    <file-upload
        style="height: 0; float:left;"
        :post-action="baseUrl+'base/uploadfile'"
        :multiple="true"
        :directory="false"
        :drop="true"
        :drop-directory="true"
        @input-file="inputFile"
        @input-filter="inputFilter"
        v-model="files"
        :id="this.id"
        name="files"
        :accept="accept"
        ref="upload">
    </file-upload>

    <div class="example-drag">
      <div v-show="$refs.upload && $refs.upload.dropActive" class="drop-active">
        <h3>Drop files to upload</h3>
      </div>
    </div>

    <button class="btn btn-primary" @click="open()">
      Załaduj plik
    </button>
    <button class="btn btn-info" @click="this.$refs.fileModal.open()">Wybierz plik z bazy</button>

  </div>
</template>

<script>

import FileUpload from 'vue-upload-component'
import ShowFiles from "../Show/Files";
import ShowGallery from "@/Library/Show/Gallery";
import axios from "@/axios";

export default {
  components: {
    ShowGallery,
    ShowFiles,
    FileUpload,
  },
  emits: ['updateValue', 'updateFiles'],
  props: {
    id: {},
    name: {
      default() {
        return 'files'
      }
    },
    preload_files: {},
    accept: {
      default() {
        return 'image/*'
      }
    }
  },
  data() {
    return {
      files: [],
    }
  },
  computed: {

    baseUrl() {
      return axios.defaults.baseURL;
    },
  },
  methods: {
    val(val = null) {

      if (val !== null) {
        this.files = val;
      }
      if (val === null) {

        let file_names = [];
        this.files.map(function (file) {
          file_names.push(file.filename);
        })
        return file_names;
      }

    },

    remove(file) {

      let ret = this.$refs.upload.remove(file);

      this.files = this.files.filter(element => element.filename !== file.filename)

    },

    uploadClipboard(e) {

      let dataTransfer = e.clipboardData
      if (!dataTransfer) {
        return
      }
      this.$refs.upload.addDataTransfer(dataTransfer)
    },

    open() {
      this.$refs.upload.$el.querySelector('input').click()
    },

    clear() {
      this.files = [];
    },

    updateFileBank(data) {

      this.files.push(data);

      this.updateValue();

    },


    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Before adding a file
        // 添加文件前
        // Filter system files or hide files
        // 过滤系统文件 和隐藏文件
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent()
        }
        // Filter php html js file
        // 过滤 php html js 文件
        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent()
        }

      }

      if (newFile && newFile.error === "" && newFile.file && (!oldFile || newFile.file !== oldFile.file)) {
        // Create a blob field
        // 创建 blob 字段
        newFile.blob = ''
        let URL = (window.URL || window.webkitURL)
        if (URL) {
          newFile.blob = URL.createObjectURL(newFile.file)
        }
        // Thumbnails
        // 缩略图
        newFile.thumb = ''
        if (newFile.blob && newFile.type.substr(0, 6) === 'image/') {
          newFile.thumb = newFile.blob
        }
      }
      // image size
      // image 尺寸
      if (newFile && newFile.error === '' && newFile.type.substr(0, 6) === "image/" && newFile.blob && (!oldFile || newFile.blob !== oldFile.blob)) {
        newFile.error = 'image parsing'
        let img = new Image();
        img.onload = () => {
          this.$refs.upload.update(newFile, {error: '', height: img.height, width: img.width})
        }
        img.οnerrοr = (e) => {
          this.$refs.upload.update(newFile, {error: 'parsing image size'})
        }
        img.src = newFile.blob
      }
    },
    // add, update, remove File Event
    inputFile(newFile, oldFile) {

      if (newFile && !oldFile) {
        // Add file
        this.$refs.upload.active = true;
      }

      if (newFile && oldFile) {
        // Update file

        // Start upload
        if (newFile.active !== oldFile.active) {
          console.log('Start upload', newFile.active, newFile)

          newFile.upload_date = new Date();
        }

        // Upload progress
        if (newFile.progress !== oldFile.progress) {
          console.log('progress', newFile.progress, newFile)
        }

        // Upload error
        if (newFile.error !== oldFile.error) {
          console.log('error', newFile.error, newFile)
        }

        // Uploaded successfully
        if (newFile.success !== oldFile.success) {
          console.log(newFile)

          console.log('success', newFile.success, newFile)
          // console.log('xhr', JSON.parse(newFile.xhr.response));

          let data = newFile.response;

          newFile.filename = data.filename;
          newFile.url = data.url;

        }

        // Automatic upload
        if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
          if (!this.$refs.upload.active) {
            this.$refs.upload.active = true;
          }
        }
      }

      if (!newFile && oldFile) {
        // Remove file

        // Automatically delete files on the server
        if (oldFile.success && oldFile.response.id) {
          // $.ajax({
          //   type: 'DELETE',
          //   url: '/file/delete?id=' + oldFile.response.id,
          // });
        }
      }
    },
    updateValue() {

      let file_names = [];
      this.files.map(function (value_new) {
        file_names.push(value_new.filename);
      })

      this.$emit('updateFiles', this.files);
      this.$emit('updateValue', this.name, file_names, this.files);
    }
  },
  mounted() {
    this.files = this.preload_files;
  },
  watch: {
    files(value_new) {

      this.updateValue();

    },
    id() {
      if (this.preload_files !== undefined)
        this.files = this.preload_files;
    }
  }
}
</script>

<style scoped lang="scss">
.example-drag .drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: .6;
  text-align: center;
  background: #000;
}

.example-drag .drop-active h3 {
  margin: -.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #fff;
  padding: 0;
}


.example-drag .drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: .6;
  text-align: center;
  background: #000;
}

.example-drag .drop-active h3 {
  margin: -.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #fff;
  padding: 0;
}

.btn.btn-primary{

  border-radius: 8px;
  background-color: #00612E;


  font-weight: 500;
  font-size: 1rem;
  line-height: 1.71rem;
  color: #FFFFFF;
  padding: 6px 16px;
}

</style>