<template>
  <textarea class="row-nice__textarea" v-model="value_local" @change="updateValue"></textarea>
</template>

<script>
import InputBase from "./InputBase";

export default {
  name: "InputTextarea",
  extends: InputBase,
  props: ['name','value'],
  data: () => ({
    value_local: null,
  }),
  methods: {
    val(){
      return this.value_local;
    },
    updateValue: function (value) {
      this.$emit('updateValue', this.name, this.value_local);
    }
  },

  mounted() {
    this.value_local = this.value;
  },
  watch: {

    value(newValue, oldValue) {

      this.value_local = newValue;

    },
  },
}
</script>

<style scoped>

</style>